import styled from "@emotion/styled"
import React from "react"
import { fonts, device, colors } from "../layout/GlobalStyles"
import partnerships from "../../images/programs/partnerships.svg"
import mloveLogo from "../../images/programs/logo-mlove.svg"
import mlifeLogo from "../../images/logos/logo-regular.svg"
import sdgLogo from "../../images/programs/logo-un.svg"

const BioContainer = styled.section`
  padding: 0px 0 48px;
  .line {
    width: 1px;
    height: 80px;
    background: black;
  }
  @media ${device.laptop} {
    padding-bottom: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const LeftPanel = styled.div`
  padding: 58px 0px;
  h2 {
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    margin: 0em 0px 10px 00px;
    line-height: 65px;
  }
  p {
    margin: 0 0px 30px 0px;
    color: ${colors.primary2};
    font-size: 40px;
    letter-spacing: -1.25px;
    line-height: 48px;
    font-family: ${fonts.secondary};
  }
  span {
    font-weight: 500;
    position: relative;
    top: 10px;
    font-family: Montserrat;
    font-size: 18px;
    letter-spacing: -0.56px;
    line-height: 27px;
  }

  div {
    margin: 15px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      width: 1px;
      height: 80px;
      position: relative;
      top: 10px;

      background: black;
    }
    .logo {
      width: 140.3px;
    }
  }
  @media ${device.laptop} {
    h2 {
      margin-bottom: 25px;
    }
    margin-bottom: 70px;
    div {
      margin: -5px 0px 20px;
    }
    p + div {
      width: 363px;
    }
    min-width: 463px;
    p {
      max-width: 418px;
    }

    p + div {
      width: 363px;
    }
  }
  @media ${device.laptopL} {
  }
`

const RightPanel = styled.div`
  div {
    display: flex;
  }
  p {
    font-size: 22px;
  }
  .line {
    height: 85px;
    width: 1px;
    background: ${colors.black};
  }
  .logo-container {
    justify-content: space-between;
  }
  @media ${device.laptop} {
    div {
      width: 326px;
      margin-top: 0px;
    }
    margin-top: 130px;
    max-width: 553px;
    margin-bottom: 30px;
  }
  p:first-of-type {
    margin-top: 50px;
  }
  p + p {
    margin-top: 40px;
  }
`

export const MloveBio = () => {
  return (
    <BioContainer className="container">
      <LeftPanel>
        <h2>Our Goal</h2>
        <p>Empower NextGen leaders.</p>
        <div>
          <img className="logo" src={mlifeLogo} alt="mlife logo" />
          <div></div>
          <img src={sdgLogo} alt="SDG logo" />
        </div>
        <span>MLIFE support(s) the Sustainable Development Goals. </span>
      </LeftPanel>
      <RightPanel>
        <div className="logo-container">
          <img className="program-logo" src={mloveLogo} alt="mlove logo" />
          <div className="line"></div>
          <img
            src={partnerships}
            className="box-image"
            alt="partnerships for the goals"
          />
        </div>
        <section>
          <p>
            Sub-Saharan Africa has the youngest population globally, with 70% of
            its population under 30. Almost 2/3 of this population are
            underemployed and from an economically disadvantaged background. It
            is Africa's most tremendous potential lying in waste.
          </p>
          <p>
            Young people lead the MLOVE program for young people. We build a
            better world by providing the NextGen leaders with a platform to
            learn, innovate, and connect with others to realize goals that
            matter to them
          </p>
        </section>
      </RightPanel>
    </BioContainer>
  )
}
