import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"
import { Fancybox } from "../Fancy-Box"
import { ModalBlock } from "../Modal"
import { modalText } from "../modalText"
import { Button } from "../layout/StyledComponents"

const MainContainer = styled.div`
  padding: 72px 0px;
  background-color: #3e7ab1;
  color: ${colors.white};
  h4 {
    color: ${colors.white};
    font-size: 30px;
    line-height: 38px;
  }
  p {
    color: ${colors.white};
    letter-spacing: -0.69px;
    line-height: 30px;
    font-size: 22px;
  }
  @media ${device.tablet} {
    img {
      width: 500px;
    }
  }
  @media ${device.laptop} {
    padding: 128px 0px 128px;
    .video-image {
      img {
        width: 526px;
      }
    }

    .text-container {
      h4 {
        margin-bottom: 25px;
        font-size: 50px;
        font-weight: 800;
        letter-spacing: -1.56px;
        line-height: 55px;
        margin: 0;
      }
      width: 480px;
      margin-left: 40px;
    }
  }
`

export const FuturePanel = () => {
  return (
    <MainContainer>
      <PanelContainer>
        {" "}
        {/* <Fancybox options={{ infinite: false }}>
          <button
            style={{ border: "none", background: "none" }}
            data-fancybox="gallery"
            data-src="https://vimeo.com/724244308"
          > */}
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://vimeo.com/724244308"
        > */}
        <StaticImage
          className="video-image"
          src="../../images/programs/mlove/future-play.png"
          placeholder="none"
          quality={100}
          alt="man standing by pool"
        />
        {/* </button>
        </Fancybox> */}
        {/* </a> */}
        <div className="text-container">
          <h4>Human ’24 - Believe in a Better Tomorrow</h4>
          <p>
            MLIFE will host a dynamic gathering of rebels passionately committed
            to the well-being of the people and the planet. Join us in Nakuru,
            Kenya.
          </p>
          <button style={{ border: "none", background: "none" }}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.eventbrite.com/e/human-24-tickets-900385656737?aff=oddtdtcreator"
            >
              {/* <ModalBlock modalText={modalText.symposium.text}> */}
              <Button color="yellow">Get details & RSVP</Button>
              {/* </ModalBlock> */}
            </a>
          </button>
        </div>
      </PanelContainer>
    </MainContainer>
  )
}
