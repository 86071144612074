import styled from "@emotion/styled"
import React from "react"
import { device } from "../layout/GlobalStyles"
import mloveHero from "../../images/programs/mlove/standing-on-box.svg"
import mloveLogo from "../../images/programs/logo-mlove-no-title.svg"
import { HeroContainer } from "./Hero-Container"

const Container = styled.div`
  .mlove-logo {
    width: 65px;
    margin-bottom: 25px;
  }
  @media ${device.laptop} {
    margin-bottom: -38px;
    .mlove-logo {
      margin-top: -70px;
    }
    .hero-image {
      min-width: 700px;
    }
  }
`

export const MloveHero = () => {
  return (
    <Container>
      <HeroContainer className="container">
        <div>
          <img className="mlove-logo" src={mloveLogo} alt="mlove logo" />
          <h1>MLOVE</h1>
          <p>Learning. Loving. Leading</p>
        </div>
        <img src={`${mloveHero}`} alt="animated students in classroom" />
      </HeroContainer>
    </Container>
  )
}
