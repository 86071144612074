import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"

const MainContainer = styled.div`
  padding: 72px 0px 62px;
  background: ${colors.primary2};
  color: ${colors.white};
  h4 {
    margin: 0;

    color: ${colors.white};
    font-size: 50px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 55px;
  }
  li {
    width: 222px;
    font-size: 22px;
    font-family: ${fonts.primary};
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.laptop} {
    h4 {
      margin-top: 15px;
    }
    .flex-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  @media ${device.laptop} {
    padding: 120px 0px;
  }
`

const EnrollmentContainer = styled.div`
  p {
    color: ${colors.white};
    font-size: 22px;
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 22px;
    max-width: 340px;
  }
  padding-bottom: 54px;

  border-bottom: 1px solid rgba(227, 191, 42, 0.4);
  @media ${device.tablet} {
    p {
      max-width: 651px;
    }
  }
`

const DeadlinesContainer = styled.div`
  padding-top: 47px;
  border-bottom: 1px solid rgba(227, 191, 42, 0.4);
  .list-container {
    margin-bottom: 40px;
  }
  @media ${device.laptop} {
    .dates {
      margin-left: 75px;
      li {
        font-weight: 900;
      }
    }
    .list-container {
      max-width: 651px;
      ul {
        padding: 0;
        list-style: none;
      }
      display: flex;
      width: 800px;
    }
  }
`

const EligibilityContainer = styled.div`
  padding: 67px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 22px;
  }
`

const EligibilityLeftColumn = styled.div`
  margin-bottom: 72px;
  h4 {
    margin: 0;
  }
  p {
    width: 222px;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    margin-bottom: 0px;
  }
`

const ElibilityRightColumn = styled.div`
  max-width: 340px;
  @media ${device.tablet} {
    max-width: 721px;
    .number-container {
      margin-top: 20px;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
  }
`

const NumberPanel = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: start;
  padding: 0;
  li,
  p {
    color: ${colors.white};
  }
  span {
    font-family: ${fonts.secondary};
    font-size: 28px;
    font-weight: 700;
    padding: 2px 15px;
    min-width: 18px;
    display: flex;
    justify-content: center;
    border: 4px solid rgba(227, 191, 42, 0.4);
    border-radius: 200px;
  }
  p {
    margin: 0px 0px 20px 35px;
    width: 188px;
  }
  @media ${device.tablet} {
    p {
      width: 565px;
    }
  }
`

const BottomList = styled.div`
  margin-top: 25px;
  margin-left: 110px;
  ul {
    position: relative;
    right: 63px;

    padding-bottom: 54px;
  }
  li {
    font-family: ${fonts.primary};
    font-size: 22px;
    font-weight: 400;

    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    li {
      width: 505px;
    }
    margin-top: 0px;
  }
  @media ${device.laptop} {
    margin-top: 00px;
    ul {
      padding-left: 40px;
    }
    button {
      margin-bottom: 20px;
    }
    .bottom-container {
      margin-top: 55px;
      li {
        margin-bottom: 0px;
      }
      li::before {
        font-size: 29px;
      }
    }
  }
`

const CourseComponents = styled.div`
  padding: 54px 0px 0px;
  h4 {
    max-width: 354px;
  }
  border-top: 1px solid rgba(227, 191, 42, 0.4);
  @media ${device.tablet} {
    ul {
      width: 625px;
      li {
        width: 599px;
      }
    }
  }
`

export const Enrollment = () => {
  return (
    <MainContainer>
      <EnrollmentContainer className="flex-container container">
        <h4>Enrollment</h4>
        <p>
          Applications for the next class of MLOVE Fellows will be open from
          January 1 through March 31! We do not accept applications for our
          MLIFE Fellows Program after March 31.
        </p>
      </EnrollmentContainer>
      <DeadlinesContainer className="flex-container container">
        <h4>Deadlines</h4>
        <div className="list-container">
          <ul>
            <li>Applications open</li>
            <li>Applications closes </li>
            <li>Determinations sent</li>
            <li>Acceptance deadline</li>
          </ul>
          <ul className="dates">
            <li>January 1</li>
            <li>March 31 </li>
            <li>May 1</li>
            <li>May 15</li>
          </ul>
        </div>
      </DeadlinesContainer>
      <EligibilityContainer className="container">
        <div className="flex-container">
          <EligibilityLeftColumn>
            <h4>Eligibility</h4>
          </EligibilityLeftColumn>
          <ElibilityRightColumn>
            <div className="number-container">
              <NumberPanel>
                <span>1</span>
                <p>Age: 21-35 years</p>
              </NumberPanel>
              <NumberPanel>
                <span>2</span>
                <p>
                  Demonstrated leadership skills or potential growth in
                  for-profit or non-profit organizations
                </p>
              </NumberPanel>
              <NumberPanel>
                <span>3</span>
                <p>
                  Commitment to learning about intersectionality and inclusion
                </p>
              </NumberPanel>
              <NumberPanel>
                <span>4</span>
                <p>
                  Remarkable interpersonal skills in writing, speaking or
                  working with others
                </p>
              </NumberPanel>
              <NumberPanel>
                <span>5</span>
                <p>
                  English language fluency, inclusive of reading, writing and
                  speaking.
                </p>
              </NumberPanel>
              <div style={{ marginBottom: "36px" }}>
                {/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://noteforms.com/forms/applications-1r5d0e"
                >
                  <Button color="yellow">Apply today</Button>
                </a> */}
              </div>
            </div>
          </ElibilityRightColumn>
        </div>
      </EligibilityContainer>
      <CourseComponents className="flex-container container">
        <h4>Course Components</h4>
        <ul>
          <li>Six 1:1 coaching sessions.</li>
          <li>Three The Future is Human Leadership Retreats</li>
          <li>Bi-weekly mentorship sessions</li>
          <li>(TBD) stipend a month for a whole year</li>
          <li>1:1 cohort accountability</li>
        </ul>
      </CourseComponents>
    </MainContainer>
  )
}
