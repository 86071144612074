import React, { useState, useContext } from "react"
import { MloveHero } from "../../components/heros/Mlove-Hero"
import { MloveBio } from "../../components/programs-bio/Mlove-Bio"
import { BecomeOneOfUs } from "../../components/Become-One-Of-Us"
import { MLovePanels } from "../../components/panels/Mlove-Panels"
import { ProgramBluePanel } from "../../components/panels/Program-Blue-Panel"
import { ImageContainer } from "../../components/Image-Container"
import { StaticImage } from "gatsby-plugin-image"
import { Enrollment } from "../../components/Enrollment"
import { ActivitiesPanel } from "../../components/panels/Activities-Panel"
import { FuturePanel } from "../../components/panels/Future-Panel"
import { ProgramsSchedule } from "../../components/Programs-Schedule"
import { Seo } from "../../components/Seo"
import { siteContext } from "../../layout/SiteContextProvider"

const MLove = () => {
  const [hovered, setHovered] = useState(false)
  const { toolTipMobile, setToolTipMobile } = useContext(siteContext)
  return (
    <>
      <Seo
        title="MLOVE - Learning. Loving. Leading."
        description="MLOVE is a premier multidisciplinary and multicultural fellowship for under-resourced high-impacting and high-potential NextGen leaders."
      />{" "}
      {/* <Nav /> */}
      <MloveHero />
      <MLovePanels
        toolTipMobile={toolTipMobile}
        setToolTipMobile={setToolTipMobile}
        hovered={hovered}
        setHovered={setHovered}
      />
      <ImageContainer>
        <StaticImage
          src="../../images/programs/mlove/people-meeting.jpeg"
          placeholder="none"
          quality={100}
          alt="people having a meeting"
        />
      </ImageContainer>
      <MloveBio />
      <ProgramBluePanel>
        {" "}
        <StaticImage
          className="top-image"
          src="../../images/programs/mlove/mlove-scholar.png"
          placeholder="none"
          quality={100}
          alt="MLOVE scholar student"
        />
        <div className="text-container">
          <p>
            "I take this opportunity to celebrate having met you and becoming
            your mentee. In my 20s, I was able to get direction in this life and
            a dream to pursue. Thank you so much for the excellent work you
            started on this young soul. I celebrate you as my hero. My life
            began at MLIFE."
          </p>
          <p>Mwangi Ndegwa - 2020 MLIFE Fellow </p>
        </div>
      </ProgramBluePanel>
      <Enrollment />
      <ActivitiesPanel />
      <ImageContainer>
        <StaticImage
          src="../../images/programs/mlove/student-leaders.jpeg"
          placeholder="none"
          quality={100}
          alt="Mwangi and students"
        />
      </ImageContainer>
      <ProgramsSchedule />
      <FuturePanel />
      <BecomeOneOfUs />
      {/* <Footer /> */}
    </>
  )
}

export default MLove
