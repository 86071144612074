import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "../layout/GlobalStyles"
import { PanelContainer } from "./Panel-Container"

const MainContainer = styled.div`
  background: ${colors.secondary102};
  color: ${colors.white};
  padding: 72px 0px;
  p {
    color: ${colors.white};
  }
  .top-image {
    position: relative;
    left: 50px;
    margin-bottom: 30px;
  }
  p:first-of-type {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 28px;
    font-family: ${fonts.secondary};
  }
  p:last-of-type {
    font-family: ${fonts.primary};
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.56px;
    line-height: 27px;
  }
  @media ${device.tablet} {
    padding: 72px 0px;
    .top-image {
      left: 0px;
    }
    img {
      width: 380px;
    }
  }
  @media ${device.laptop} {
    padding: 108px 0px 100px;
    .text-container {
      width: 733px;
      position: relative;
      right: 70px;
    }
    img {
      width: 228px;
    }
  }
`

export const ProgramBluePanel = ({ children, reverse }) => {
  return (
    <MainContainer>
      <PanelContainer reverse={reverse}>{children}</PanelContainer>
    </MainContainer>
  )
}
